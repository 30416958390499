import React, { useState } from 'react';

const ExpandableWidget = ({ 
    title, 
    content,
    link1,
    link1text, 
    image1, 
    image2,
    image3, 
    image1alt, 
    image2alt,
    image3alt,
    customHeight
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  let style = {
    height: ''
  }

  if (customHeight) {
    style.height = customHeight;
  } else {
    style = {};
  }

  return (
    <div className="expandable-widget">
        <div className="widget-header" onClick={toggleExpand}>
        <h1 className='name'>{title}</h1>
            {isExpanded && (
            <div className='box' style={style}>
                <div className='inner'>
                    <div className="widget-content">
                      <h2>{content}</h2>
                      <a className="widget-link" href={link1}>{link1text}
                        <img src={image1} className="widget-img" alt={image1alt}/>
                        <img src={image2} className="widget-img" alt={image2alt}/>
                        <img src={image3} className="widget-img" alt={image3alt}/>
                      </a>
                    </div>
                </div>
            </div>
            )}
        </div>
    </div>
  );
};

export default ExpandableWidget;