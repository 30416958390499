import danelia from './danelia.png';
import flower1 from './flower-1.png';
import flower2 from './flower-2.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
        <h1 className="title-name">Danelia Casco</h1>
        <div className='header-align'>
        <div className='left-align'>
          <img src={flower1} alt="flores bellas" className='header-img'/>
        </div>
        <div className='center-align'>
          <img src={danelia} className="danelia-image" alt="Danelia Casco Supervisora en Jafra Cosméticos y Manteles"/>
        </div>
        <div className='right-align'>
          <img src={flower2} alt="flores bellas" className='header-img'/>
        </div>
        </div>
        </div>
      </header>
    </div>
  );
}

export default App;
