import React from 'react';
import ExpandableWidget from './ExpandableWidget';
import jafraimage1 from "./jafra-1.png"
import jafraimage2 from "./jafra-2.png"
import jafraimage3 from "./jafra-3.jpg"

const text1 = 'Tengo más de 30 años vendiendo productos de Jafra Cosméticos.\n\n'+ 
'Por favor deme una llamada para recibir buenas ofertas o si quiere empezar su propio negocio en casa.\n'+ 
'¡No pierdas tiempo!\n';

const Jafra = () => {
  return (
    <div className="jafra">
      <ExpandableWidget
        title="Jafra Cosméticos"
        content={text1}
        link1="https://www.jafra.com/beauty-consultant/daneliacasco?personalWebsite=0200779851&lang=es"
        link1text="Ver Ofertas Directas"
        image1={jafraimage3}
        image2={jafraimage1}
        image3={jafraimage2}
        image2alt="Jafra Cosméticos Royal Jelly"
        image3alt="Jafra Cosméticos Productos"
        image1alt="Jafra Cosméticos ClearSmart Royal"
        customHeight="1060px"
        />
    </div>
  );
};

export default Jafra;