import React from 'react';
import ExpandableWidget from './ExpandableWidget';
import mantelesimage1 from "./manteles-1.jpg"
import mantelesimage2 from "./manteles-2.jpg"

const Manteles = () => {
    return (
    <div className="manteles">
        <ExpandableWidget
        title="Rento Manteles"
        content="¡Ofrezco todo tipo de productos para sus fiestas! Rento manteles, cuberturas para sillas, adornos y más! ¡Garantizado a ganarle el precio a cualquier otra persona!"
        image1={mantelesimage1}
        image2={mantelesimage2}
        image1alt="Rento Manteles Para Fiestas"
        image2alt="Rento Manteles Para Fiestas"
        customHeight="765px"
        />
    </div>
    );
};

export default Manteles;