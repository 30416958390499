import React from 'react';

const Footer = () => {
  return (
    <footer>
        <div className='footer'>
        <h1 className='name'>Contacto</h1>
        <div className='box'>
            <div className='inner'>
                <div className="widget-content">
                <h2>Llama o manda un texto a Danelia Casco al:</h2>
                <h1><bold><a href="tel:510-563-9602">510.563.9602</a></bold></h1>
                <h2>O un correo electronico a: <a href='mailto:daneliacasco@gmail.com'>DaneliaCasco@Gmail.com</a></h2>
                <h2>¿Que esperas?</h2>
                </div>
            </div>
        </div>
        </div>
    </footer>
  );
};

export default Footer;